<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Compare quantidade e valor total de entradas e saídas por loja de acordo com os filtros selecionados. Clique sobre um valor para visualizar seus respectivos comprovantes.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
						<div class="col-xl-7 px-0">
							<div class="row">
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-file color-theme font-11 me-1"></i> Categoria</label>
									<v-select name="Desktop" :options="listaCategorias" v-model="pesquisa.categoria" label="nome" :reduce="c => c.nome" placeholder="Todas as categorias"
										@input="buscarSubcategorias(pesquisa.categoria)">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.categoria" @change="buscarSubcategorias(pesquisa.categoria)">
										<option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria</label>
									<v-select name="Desktop" :options="listaSubcategorias" v-model="pesquisa.subcategoria" placeholder="Todas as subcategorias">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.subcategoria">
										<option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-bookmark color-theme font-11 me-1"></i> Marca</label>
									<v-select name="Desktop" :options="listaMarcas" v-model="pesquisa.marca" label="nome" :reduce="m => m.nome" placeholder="Todas as marcas">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Marcas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.marca">
										<option v-for="(option, index) in listaMarcas" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-3 mb-3">
									<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
									<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
										<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.loja">
										<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-xl-5 px-0">
							<div class="row">
								<div class="col-sm-5 col-md-4 mb-3">
									<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
									<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
								</div>
								<div class="col-9 col-sm-5 col-md-6 mb-3">
									<label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome/Código/Família</label>
									<input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarEntradasSaidas" placeholder="Busque por nome do produto, código ou família" />
								</div>
								<div class="col-3 col-sm-2 mb-3 align-self-end">
									<button type="button" class="btn btn-primary w-100" @click="buscarEntradasSaidas"><i class="fas fa-search font-13"></i></button>
								</div>
							</div>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
         <loja v-for="(loja, index) in pesquisa.retorno.resultado" :key="index" :loja="loja" :index="index" :data="pesquisa.retorno.data" @buscarComprovantes="buscarComprovantes($event, false)" />
      </div>

      <!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">Comprovantes - Loja {{ modal.numeroLoja }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="modal.resultado.length > 0">
                  <div class="cards-150 cards-sm-200">
                     <comprovante v-for="(cupom, index) in modal.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
                  </div>

                  <div class="w-100 mt-3 mb-2 text-center" v-if="modal.resultado.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
                  <i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum comprovante encontrado</h4>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" v-if="modal.resultado.length > 0" @click="buscarComprovantes(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<modalComprovante :modalAnterior="'#modalComprovantes'" :tipo="comprovante.tipo" :comprovante="comprovante.dados" />
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import comprovante from '@/components/resultados/Comprovante.vue'
import loja from '@/components/resultados/entradasSaidas/Loja.vue'
import modalComprovante from '@/components/resultados/ModalComprovante.vue'

export default {
	name: 'Entradas_e_saidas',
	data: function () {
      return {
         pesquisa: {
				'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'loja': null, 'data': [new Date(), new Date()], 
				'retorno': {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'loja': null, 'data': null, 'resultado': []}
			},
			modal: {
				'numeroLoja': null, 'loja': null, 'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'data': [new Date(), new Date()], 'isEntrada': false, 
				'pagina': 0, 'resultado': []
			},
			comprovante: {'tipo': null, 'dados': null},
			listaSubcategorias: [],
			listaExportacao: []
		}
	},
	computed: {
      ... mapState({
			listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
      loja, comprovante, modalComprovante
   },
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalComprovantes").modal('hide')
			$("#modalVerComprovante").modal('show')
		},
		buscarEntradasSaidas : function () {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}
			
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

         if (this.pesquisa.loja == null) this.pesquisa.loja = 0;

			this.$axios({
				method: 'post',
				url: this.urlRest +'resultados/searchEntradasSaidas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.pesquisa.subcategoria = null
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data

				if (data.length > 0) {
					this.pesquisa.subcategoria = data[0]
				}
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		buscarComprovantes : function (objLoja, isExcel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'numeroLoja': objLoja != null ? objLoja.numeroLoja : this.modal.numeroLoja, 
				'loja': objLoja != null ? objLoja.idLoja : this.modal.loja, 
				'isEntrada': objLoja != null ? objLoja.isEntrada : this.modal.isEntrada, 
				'categoria': this.pesquisa.retorno.categoria, 
				'subcategoria': this.pesquisa.retorno.subcategoria, 
				'nome': this.pesquisa.retorno.nome, 
				'marca': this.pesquisa.retorno.marca, 
				'data': this.pesquisa.retorno.data, 
				'pagina': isExcel ? -1 : (objLoja != null ? 0 : this.modal.pagina),
				'resultado': objLoja != null ? [] : this.modal.resultado
			}

			$('#modalComprovantes').modal('show')
			Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

         this.$axios({
            method: 'post',
            url: this.urlRest +'resultados/searchComprovantesProduto',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

         }).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.modal.pagina++;
               this.modal.resultado.push(... response.data)
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)

            if (isExcel) {
					this.$store.dispatch('exportarExcel', {
						'nome': 'Comprovantes',
						'resultado': this.listaExportacao, 
						'campos': ['id', 'motivo', 'nomeLoja', 'data', 'valor'], 
						'lista': null
					})
				}
         });
      }
	},
   mounted() {
      this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
      Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
   }
}

</script>