<template>
   <div class="card mb-1">
		<div class="card-body p-12">
         <div class="row">
            <div class="col mb-2 align-self-center">
               <div class="limitador-1 mb-1">
                  <i class="far fa-store color-theme font-13 me-1"></i><strong class="subtitle font-15 text-capitalize"> {{ loja.nomeLoja }}</strong>
                  <small class="mx-2">|</small><span class="font-13"><i class="far fa-tag font-11 me-1"></i> {{ loja.numeroLoja }}</span>
               </div>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default-outline mb-1 cursor-auto d-none d-xl-block">
                  <i class="far fa-info-circle me-1"></i> Entradas/Saídas contabilizados na loja de {{ dataInicio.getDate().toString().padStart(2, "0") +'/'+ (dataInicio.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataInicio.getFullYear() }} até {{ dataFim.getDate().toString().padStart(2, "0") +'/'+ (dataFim.getMonth() + 1).toString().padStart(2, "0") +'/'+ dataFim.getFullYear() }}
					</a>
            </div>
            <div class="w-max-content mb-2">
               <a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
            </div>
         </div>

			<div class="row font-13">
            <div class="col-6 pe-0 cursor-pointer" @click="buscarComprovantes(true)">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-1">
                        <div class="card-body p-2 weight-500 text-green text-center"><i class="far fa-dolly me-2 font-11"></i>Entrada</div>
                     </div>
                  </div>
                  <div class="col-xl-6 pe-xl-0">
                     <div class="card mb-1 invert">
                        <div class="card-body p-2">
                           <div class="row">
                              <div class="col-sm-6"><i class="far fa-box me-2 font-11 color-theme"></i><strong class="me-1 weight-500">Quantidade</strong></div>
                              <div class="col-sm-6 text-sm-end">{{ loja.quantidadeEntrada == null ? '0' : parseFloat(loja.quantidadeEntrada).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6">
                     <div class="card mb-0 invert">
                        <div class="card-body p-2">
                           <div class="row">
                              <div class="col-sm-6"><i class="far fa-money-bill-wave-alt me-2 font-11 color-theme"></i><strong class="me-1 weight-500">Valor</strong></div>
                              <div class="col-sm-6 text-sm-end"><small>R$</small> {{ loja.valorEntrada == null ? '0,00' : parseFloat(loja.valorEntrada).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-6 ps-0 cursor-pointer" @click="buscarComprovantes(false)">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-1">
                        <div class="card-body p-2 weight-500 text-red text-center"><i class="far fa-shopping-cart me-2 font-11"></i>Saída</div>
                     </div>
                  </div>
                  <div class="col-xl-6 pe-xl-0">
                     <div class="card mb-1 invert">
                        <div class="card-body p-2">
                           <div class="row">
                              <div class="col-sm-6"><i class="far fa-box me-2 font-11 color-theme"></i><strong class="me-1 weight-500">Quantidade</strong></div>
                              <div class="col-sm-6 text-sm-end">{{ loja.quantidadeSaida == null ? '0' : parseFloat(loja.quantidadeSaida).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6">
                     <div class="card mb-0 invert">
                        <div class="card-body p-2">
                           <div class="row">
                              <div class="col-sm-6"><i class="far fa-money-bill-wave-alt me-2 font-11 color-theme"></i><strong class="me-1 weight-500">Valor</strong></div>
                              <div class="col-sm-6 text-sm-end"><small>R$</small> {{ loja.valorSaida == null ? '0,00' : parseFloat(loja.valorSaida).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Loja',
	props: ['loja', 'index', 'data'],
   data: function () {
      return {
			dataInicio: new Date(this.data[0]),
			dataFim: new Date(this.data[1])
      }
   },
   methods: {
      exportar : function () {
         this.$store.dispatch('exportarExcel', {
				'nome': ('Entradas e saídas - '+ this.loja.nomeLoja),
				'resultado': Array.of(this.loja), 
				'campos': ['numeroLoja', 'nomeLoja', 'quantidadeEntrada', 'valorEntrada', 'quantidadeSaida', 'valorSaida'], 
				'lista': null
			})
      },
      buscarComprovantes : function (isEntrada) {
         this.$emit('buscarComprovantes', {'idLoja': this.loja.idLoja, 'numeroLoja': this.loja.numeroLoja, 'isEntrada': isEntrada})
      }
   }
}

</script>